import { Component } from "../../../libs/components/component";


const minimizedClass = "target-group--minimized";

class TargetGroup extends Component {
    onInit(): void {
        this.host.addEventListener('click', () => {
            this.host.classList.remove(minimizedClass);
        });
    }
}
TargetGroup.declare(`.${minimizedClass}`);